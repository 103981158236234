exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-apply-jsx": () => import("./../../../src/templates/apply.jsx" /* webpackChunkName: "component---src-templates-apply-jsx" */),
  "component---src-templates-new-solution-jsx": () => import("./../../../src/templates/new-solution.jsx" /* webpackChunkName: "component---src-templates-new-solution-jsx" */),
  "component---src-templates-solution-jsx": () => import("./../../../src/templates/solution.jsx" /* webpackChunkName: "component---src-templates-solution-jsx" */),
  "component---src-templates-virtual-reality-index-js": () => import("./../../../src/templates/virtual-reality/index.js" /* webpackChunkName: "component---src-templates-virtual-reality-index-js" */),
  "component---src-views-404-html-jsx": () => import("./../../../src/views/404.html.jsx" /* webpackChunkName: "component---src-views-404-html-jsx" */),
  "component---src-views-about-html-jsx": () => import("./../../../src/views/about.html.jsx" /* webpackChunkName: "component---src-views-about-html-jsx" */),
  "component---src-views-aigc-large-model-html-jsx": () => import("./../../../src/views/aigc-large-model.html.jsx" /* webpackChunkName: "component---src-views-aigc-large-model-html-jsx" */),
  "component---src-views-bare-metal-html-jsx": () => import("./../../../src/views/bare-metal.html.jsx" /* webpackChunkName: "component---src-views-bare-metal-html-jsx" */),
  "component---src-views-cloud-desktop-html-jsx": () => import("./../../../src/views/cloud-desktop.html.jsx" /* webpackChunkName: "component---src-views-cloud-desktop-html-jsx" */),
  "component---src-views-cloud-rendering-html-jsx": () => import("./../../../src/views/cloud-rendering.html.jsx" /* webpackChunkName: "component---src-views-cloud-rendering-html-jsx" */),
  "component---src-views-cloud-xr-jsx": () => import("./../../../src/views/cloud-xr.jsx" /* webpackChunkName: "component---src-views-cloud-xr-jsx" */),
  "component---src-views-demo-html-jsx": () => import("./../../../src/views/demo.html.jsx" /* webpackChunkName: "component---src-views-demo-html-jsx" */),
  "component---src-views-developer-center-jsx": () => import("./../../../src/views/developer-center.jsx" /* webpackChunkName: "component---src-views-developer-center-jsx" */),
  "component---src-views-partner-html-jsx": () => import("./../../../src/views/partner.html.jsx" /* webpackChunkName: "component---src-views-partner-html-jsx" */),
  "component---src-views-pricing-html-jsx": () => import("./../../../src/views/pricing.html.jsx" /* webpackChunkName: "component---src-views-pricing-html-jsx" */),
  "component---src-views-product-html-jsx": () => import("./../../../src/views/product.html.jsx" /* webpackChunkName: "component---src-views-product-html-jsx" */),
  "component---src-views-update-html-jsx": () => import("./../../../src/views/update.html.jsx" /* webpackChunkName: "component---src-views-update-html-jsx" */),
  "component---src-views-xr-activity-html-jsx": () => import("./../../../src/views/xr-activity.html.jsx" /* webpackChunkName: "component---src-views-xr-activity-html-jsx" */),
  "component---src-views-xr-microend-tool-html-jsx": () => import("./../../../src/views/xr-microend-tool.html.jsx" /* webpackChunkName: "component---src-views-xr-microend-tool-html-jsx" */)
}

